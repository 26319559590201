import { autoinject, bindable } from 'aurelia-framework';
import {
    BlockListItem,
    BlockType,
    BusinessMap,
    CounterMeasuresApiClient,
    Detection,
    DetectionsApiClient,
    DetectionStatus,
    SicAlert,
    UtmApiClient
} from 'services/cyber-api';
import { StateApi } from 'services/state-api';
import { Toast } from 'utilities/toast';
import { Utilities } from 'utilities/utilities';
import Swal from 'sweetalert2';

@autoinject()
export class UtmThreatRespondListItem {
    @bindable() public detection: Detection;
    @bindable() public businessMap: BusinessMap;
    @bindable() public fleetVessels: BusinessMap[];
    @bindable() public threat: SicAlert;
    @bindable() public utmBlock: BlockListItem;

    public allowActions: boolean;
    public actionExecuting: boolean;

    constructor(
        private detectionsApi: DetectionsApiClient,
        private countermeasureApi: CounterMeasuresApiClient,
        private utmApi: UtmApiClient,
        private state: StateApi) {
    }

    private async attached(): Promise<void> {
        // Only allow actions when the detection has a status of Open (to block), Failed (to retry) or Succeeded (to unblock or block)
        this.allowActions =
            this.detection.status === DetectionStatus.Open
            || this.detection.status === DetectionStatus.Failed
            || this.detection.status === DetectionStatus.Succeeded;
    }

    public async execute(): Promise<void> {
        this.actionExecuting = true;
        try {

            if (this.utmBlock) {
                const unblockDialog = await Swal.fire({
                    title: `Unblock`,
                    html: `Are you sure you want to unblock <br/><b>"${this.detection.target}"</b> ? <br/><small style='padding-top:15px'>${this.utmBlock.isCompanyWide ? 'This will unblock the target on all vessels in your fleet' : 'This will unblock the target on this vessel'}</small>`,
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    cancelButtonText: 'No, cancel',
                    confirmButtonText: 'Yes, unblock',
                });

                // Short-circuit when the dialog was dismissed
                if (unblockDialog.dismiss) {
                    return;
                }

                await this.utmApi.deleteBlockById(this.utmBlock.id, this.state.company(), this.detection.id);
                this.utmBlock = undefined;
            } else {
                const dialog = await Swal.fire({
                    title: `Block <b>"${this.detection.target}"</b>`,
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    cancelButtonText: 'No, cancel',
                    confirmButtonText: 'Yes, block',
                    input: "radio",
                    inputOptions: {
                        "fleet-wide": "Block on all vessels in my fleet",
                        "vessel": `Block only on <b>${this.businessMap.name}</b>`
                    },
                    inputValidator: (value) => {
                        if (!value) {
                            return 'You need to select one of the options'
                        }
                    },
                });

                // Short-circuit when the dialog was dismissed
                if (dialog.dismiss)
                    return;

                // If the target is a URL, we want to extract the domain, because that's what blocked
                let target = this.detection.target;
                try {
                    const parsedUrl = new URL(this.detection.target);

                    // Get the host part, which is the domain
                    target = parsedUrl.host;
                } catch (error) {
                    // If the URL is not valid, it's not a domain
                    // So we just ignore the error
                }
                const blockOn = dialog.value;
                let blockType = this.detection.type === 'Domain' ? BlockType.Domain : BlockType.Ip;
                if (blockOn === 'fleet-wide') {
                    this.utmBlock = await this.utmApi.addBlock(this.state.company(), target, blockType, this.detection.id);
                }
                if (blockOn === 'vessel') {
                    this.utmBlock = await this.utmApi.addBlockBySite(this.businessMap.ttSiteId.toString(), this.state.company(), target, blockType, this.detection.id);
                }
            }
        } catch (error) {
            Toast.error(`Oops, ${error}`);
        } finally {
            this.actionExecuting = false;
        }
    }

    public copyTarget(target: string): void {
        try {
            Utilities.copyToClipboard(target);
        } catch (error) {
            Toast.warning('Unable to copy target to clipboard');
            return;
        }

        Toast.info('Copied to clipboard');
    }
}
