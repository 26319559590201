import { StateApi } from 'services/state-api';
import {
    BlockListItem,
    BusinessMap,
    CompanyLightweight,
    Detection,
    DetectionsApiClient,
    GetUtmBlockRequest,
    SicAlert,
    ThreatSummary,
    UtmApiClient
} from 'services/cyber-api';
import { autoinject, bindable, computedFrom } from 'aurelia-framework';
import { Toast } from 'utilities/toast';
import { Utilities } from 'utilities/utilities';

@autoinject
export class ThreatRespond {
    @bindable() public threat: SicAlert;
    @bindable() public threatSummary: ThreatSummary;
    @bindable() public businessMap: BusinessMap;
    @bindable() public fleetVessels: BusinessMap[];
    public detections: Detection[];
    public utmBlocks: BlockListItem[];
    public error: string;
    private xchangeExpanded: boolean = true;
    private utmExpanded: boolean = true;
    private readonly requiredXchangeFirmwareVersion: string = '5.1';
    private readonly company: CompanyLightweight;

    constructor(
        private detectionsApi: DetectionsApiClient,
        private state: StateApi,
        private utmApi: UtmApiClient
    ) {
        this.company = this.state.companies().find((company) => company.id === this.state.company());
    }

    private async businessMapChanged(): Promise<void> {
        // Reset before retrieving new data
        this.error = undefined;
        this.detections = undefined;
        const isUtmActive = this.company.isUtmActive && this.businessMap.isUtmActive;

        this.xchangeExpanded = !isUtmActive || this.threatSummary.channel !== "Universal Threat Management";
        this.utmExpanded = isUtmActive;

        await this.retrieveDetections();
    }

    @computedFrom('businessMap')
    private get xchangeCompatibleHardware(): boolean {
        if (!this.businessMap.xChangeFirmwareVersion) return undefined;
        // return false;

        // #16695 [XC-CM] Do not display "Block on this vessel" button if XChange firmware is <5.1
        // note: baffled that this comparison works! Javascript is... special
        // '5.3.0' > '5.2' true
        // '5.3.0' < '5.2' false
        // '5.3.0' < '5.2.1.3' false
        // '5.3.0' > '5.2.1.3' true
        return this.businessMap.xChangeFirmwareVersion >= this.requiredXchangeFirmwareVersion;
    }

    private async retrieveDetections(): Promise<void> {
        try {
            // When XChange compatible hardware is detected, lookup any (actionable) detections a.k.a. countermeasures through the api
            let detectionsResponse = this.xchangeCompatibleHardware
                ? await this.detectionsApi.byThreatShortId(this.threat.shortId, this.state.company())
                : this.detections = [];

            let detectionTargets = detectionsResponse.map(detection => detection.target);
            let getUtmBlockRequest = new GetUtmBlockRequest({
                companyId: this.state.company(),
                siteId: null,
                take: 10,
                skip: 0,
                filter: detectionTargets,
                search: undefined,
                type: undefined,
            });

            let companyBlocklistItems = await this.utmApi.getBlocks(this.state.company(), getUtmBlockRequest);
            getUtmBlockRequest.siteId = this.businessMap.ttSiteId.toString();
            let siteBlocklistItems = await this.utmApi.getBlocks(this.state.company(), getUtmBlockRequest);

            this.utmBlocks = companyBlocklistItems.items.concat(siteBlocklistItems.items);
            // because of binding this will update the view
            this.detections = detectionsResponse;


        } catch (error) {
            // A problem occurred when retrieving detections for this threat, notify the user with an alert containing
            // the error message.
            let errorMessage: string;
            errorMessage = error['message'];

            // Replace presumably present '[NotFound]' from error message
            errorMessage = errorMessage.replace('[NotFound] ', '');

            const errorMessageJson = JSON.parse(errorMessage);
            if (errorMessageJson && 'message' in errorMessageJson)
                errorMessage = errorMessageJson['message'];

            this.error = errorMessage || 'An unknown error occurred.';
            return;
        }
    }

    private findUtmBlock(detection: Detection): BlockListItem | undefined {
        let target = detection.target;
        try {
            const parsedUrl = new URL(detection.target);

            // Get the host part, which is the domain
            target = parsedUrl.host;
        } catch (error) {
            // If the URL is not valid, it's not a domain
            // So we just ignore the error
        }
        return this.utmBlocks?.find(block => block.value === target) ?? undefined;
    }

    private copy(value: string): void {
        Utilities.copyToClipboard(value);

        Toast.info('Copied to clipboard');
    }
}
